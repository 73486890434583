@media only screen and (min-width:280) {

  .mat-label {
    top: 20px;
    font-size: 14px;
  }

  mat-icon-button {
    height: 36px !important;
  }
}

#change_password mat-card,
#forgot_password mat-card,
#login_container mat-card,
#registration_container mat-card {
  max-width: 600px;
  margin: 2em auto;
  text-align: center;
  padding: 20px;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-container-color: green;
  --mdc-snackbar-supporting-text-color: white;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: var(--mdc-snackbar-supporting-text-color, inherit);
  margin-top: 6px;
  font-size: 12px !important;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f8f3f5;
}

.mdc-linear-progress__bar-inner {
  border-top-width: white !important;
}

.mat-mdc-form-field-error {
  font-size: 12px;
}