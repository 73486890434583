@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '/src/scss/base';
@import '/src/scss/custom-material';
@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-alpine.css";
@import "node_modules/ngx-toastr/toastr.css";
@import '/src/scss/styles-ar.scss';
@import '/src/scss/styles-en.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/ngx-org-chart/_theming.scss';

body {
  font-family: 'Roboto', sans-serif;
  margin: 0 !important;
  height: 80vw;
  overflow: hidden;

}

.mdc-floating-label--required::after {
  color: red;
}

.mat-mdc-tab-list {
  flex-grow: 0 !important;
  position: relative;
  transition: transform 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.toolbar {
  background: $c-button-fields;
  color: $c-secondary-fields;
  max-height: 3.5rem;

  .profile-menu {
    flex: 1 1 auto;
  }
}

.selectLanguage {
  justify-self: right;
  font-size: 14px;
  height: 20px;
  background-color: $c-secondary-fields;
  outline: none;
  color: #ac47c0;
  border-radius: 4px;
}

.add-btn {
  position: fixed;
  right: 25px;
  bottom: 20Px;
}

.form-wraper {
  width: 100%;
  margin-top: 2%;
}

.form-container {
  width: 35%;
  margin: auto;
  padding-bottom: 24px !important;
  display: block;
}

.card {
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.form-header {
  padding: 24px 0;
}

.form-title {
  text-align: center;
  font-weight: 200;
  font-size: 1.5em;
}

.main-lock-icon {
  width: 20%;
  margin: 5px auto;
  display: block;
}

.form-body {
  padding: 0 24px;
}

.full-width {
  width: 100%;

}

.mat-mdc-form-field-icon-suffix,
[dir=rtl] .mat-mdc-form-field-icon-prefix {
  margin-top: -1em;
}

.form-footer {
  padding: 24px;
}

.btn {
  display: block;
  width: 100%;
  padding: 5% !important;
  font-size: 1.3em;
  font-weight: 150;
  margin-top: 18px;
}

.custom-btn {
  margin-bottom: 15px;
  background: $c-button-fields;
  color: $c-button-fields;
}

.google-icon {
  width: 6%;
}

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.txt {
  text-align: center;
  color: #333333;

}

.additional-link {
  padding: 10px auto;
  text-align: center;
}

.mat-mdc-form-field-infix {
  min-height: 40px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 8px 0;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  height: 0;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: #357ddd;
  --mdc-checkbox-selected-hover-icon-color: #357ddd;
  --mdc-checkbox-selected-icon-color: #357ddd;
  --mdc-checkbox-selected-pressed-icon-color: #357ddd;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $c-cyan-azure;
  color: $c-secondary-fields;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: $c-button-fields;
  margin-top: 0;
  width: 100%;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--mdc-dialog-container-shape, var(--mdc-shape-medium, 4px));
  padding: 1rem !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: $c-button-fields;
  width: 100%;
  height: 4px;
}

button {
  height: auto;
  width: auto;
  padding: 8px 1rem;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  letter-spacing: 1px;
  background: #357ddd;
  cursor: pointer;
}

.cancel-btn {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.mat-divider {
  width: 100%;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 1;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none;
}

.mat-ripple {
  display: none;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #357ddd !important;
}

.mat-mdc-slide-toggle.mat-accent {
  width: 120px;
  margin-top: 9px;
  --mdc-switch-selected-handle-color: #357ddd;
  --mdc-switch-selected-hover-state-layer-color: #357ddd;
  --mdc-switch-selected-pressed-state-layer-color: $c-button-fields#357ddd;
  --mdc-switch-selected-focus-handle-color: #357ddd;
  --mdc-switch-selected-hover-handle-color: #357ddd;
  --mdc-switch-selected-pressed-handle-color: #357ddd;
  --mdc-switch-selected-focus-track-color: #357ddd;
  --mdc-switch-selected-hover-track-color: #357ddd;
  --mdc-switch-selected-pressed-track-color: #357ddd;
  --mdc-switch-selected-track-color: #357ddd;
}

.mdc-switch__ripple {
  display: none !important;

}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
  height: var(--mdc-switch-state-layer-size, 0);
  width: var(--mdc-switch-state-layer-size, 0);
}

// department designation popup css 
.add-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 350px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgb(253, 253, 253);
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  height: auto;
  border-radius: 5px;
}

.form-content {
  margin-top: 20px;
}

.mat-mdc-select-value {
  font-size: 14px;
}

/*
   ##Device = Desktops
   ##Screen = 1281px to higher resolution desktops
 */

@media (min-width: 1281px) {}

/* 
     ##Device = Laptops, Desktops
     ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {}

/* 
     ##Device = Tablets, Ipads (portrait)
     ##Screen = B/w 768px to 1024px
   */

@media (min-width: 768px) and (max-width: 1024px) {
  .form-container {
    width: 95%;
    margin: auto;
    padding-bottom: 20px !important;
    display: block;
    background: rgb(253, 253, 253);
  }

  .btn {
    font-size: 1em;
  }
}

/* 
     ##Device = Tablets, Ipads (landscape)
     ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
     ##Device = Low Resolution Tablets, Mobiles (Landscape)
     ##Screen = B/w 481px to 767px
 
    */
@media (min-width: 481px) and (max-width: 767px) {
  .form-container {
    width: 95%;
    margin: auto;
    padding-bottom: 24px !important;
    display: block;
    background: rgb(253, 253, 253);
  }

  .btn {
    font-size: 1em;
  }
}

/* 
     ##Device = Most of the Smartphones Mobiles (Portrait)
     ##Screen = B/w 320px to 479px
   
   */
@media (min-width: 320px) and (max-width: 480px) {
  .form-container {
    width: 95%;
    margin: auto;
    padding-bottom: 24px !important;
    display: block;
    background: rgb(253, 253, 253);
  }

  .btn {
    font-size: 1em;
  }
}

h2,
h3 {
  font-weight: 100 !important;
}

.button-right {
  float: right;
}

.button-left {
  float: left;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background: none !important;
}

//input boxes size
.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-size: 14px;
}

.mat-mdc-form-field-infix {
  min-height: 27px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 3px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 15px;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 15px;
  padding-right: 20px;
}

.mat-mdc-card-content h2 {
  margin: 0 0 2rem 0;
}

button:focus-visible {
  outline: none;
}

.login-links {
  float: left;
  margin: 10px 0 0 12px;
  font-size: $h8-font-size;
}

//Admin module
// Graph title on dashboard page
.title {
  margin: 10px 0 5px 20px;
}

//filter icon on dashboard page
.filter-icon {
  margin-bottom: -7px !important;
  margin-right: -10px;
  color: rgb(183, 181, 181);
  font-size: 20px;
}

.view-graph {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  font-size: 12px;
}

// Landing Page
.section-container {
  padding: 20px;
  margin-bottom: 2rem;
  background-color: #f7f7f7;
  text-align: center;
}

.section-title {
  font-size: 32px;
  color: #0678a0;
  margin-bottom: 2rem;
}

.features-card,
.team-card,
.upcoming-card-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  gap: 1rem;
  color: white;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #357ddd;
  --mdc-radio-selected-hover-icon-color: #357ddd;
  --mdc-radio-selected-icon-color: #357ddd;
  --mdc-radio-selected-pressed-icon-color: #357ddd;
  --mat-mdc-radio-checked-ripple-color: #357ddd;
}

.policy-container {
  padding: 1rem 2rem;

  p,
  ul,
  li,
  h4 {
    color: #515978;
    font-size: 14px;
  }

  .terms-points {
    font-weight: 600;
  }

  h4 {
    font-weight: 400;
  }
}

.mat-drawer {
  position: relative;
  z-index: 4;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  outline: 0;
  box-sizing: border-box;
  overflow: visible;
  transform: translate3d(-100%, 0, 0);
}

.mat-drawer-inner-container {
  width: 100%;
  height: 100%;
  max-height: 75%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: var(--mat-expansion-container-shape);
  border-bottom-left-radius: var(--mat-expansion-container-shape);
  margin-top: 1rem;
}

mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 1;
  margin-bottom: 1rem;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2dd454;
  border-radius: 2rem;
}

.header_container {
  margin: 0 20px 0 28px;
  display: flex;
  flex-wrap: wrap;
}

.rewind {
  cursor: pointer;
}

////////////////////////////////Setting Tab///////////////////////////////
.setting__main_container {
  margin: 0 20px 0 28px;
  display: flex;
  flex-wrap: wrap;
}

.setting__heading_container {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.setting__header_button_container {
  margin: auto 0;
}

.bulk_upload_link {
  color: $c-purple;
  text-decoration: underline;
  cursor: pointer;
}

.ag-grid-custom-button mat-icon {
  font-size: 19px;
  color: green;
}

.ag-grid-custom-button.custom-green {
  color: #2ecc71;
}

.ag-grid-custom-button.custom-red mat-icon {
  color: #e74c3c;
}

.page-heading {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-left: 2rem;
  gap: 0.5rem;

  mat-icon {
    margin: auto 0;
  }

  h3 {
    margin: auto 0;
  }

}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img,
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div video {
  position: absolute !important;
  margin: auto !important;
  height: 65% !important;
  width: 75% !important;
  inset: 0 !important;
  border-radius: 5px;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background-color: #357ddd !important;
}